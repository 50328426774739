import { Box, Grid, Typography } from '@mui/material';
import HomeSlider from '../CommonComponent/homeSlider';
import { useState, useContext, useEffect } from 'react';
import ProductBlock from '@/CommonComponent/productBlock';
import { ROUTE, SeoInfo } from '@/Interface/interface';
import {
  getBrandAPI,
  getDevicesAPI,
  getHomePageBanner,
} from '@/Configuration/API';
import { Context } from './_app';
import MetaDescription from '@/CommonComponent/metaDescription';
import ErrorBoundary from './ErrorBoundary';
import { convertIntoString, getCurrentURL } from '@/Configuration/Service';
import Link from 'next/link';
import Image from 'next/image';
import defaultSmartPhone from '../Assets/Images/defaultSmartphone.jpg';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import LatestBlogs from '@/CommonComponent/latestBlogs';
import {
  getBlogs,
  getCachedHomePageBanner,
  getDeviceByTag,
} from '@/scripts/cron-job.mjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

export default function Home({
  popular,
  featured,
  brandDevice,
  locale,
  countryCode,
  homePageBanner,
  blogs,
}: any) {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);
  const { setTagArr, tagArr } = useContext(Context);

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);
  return (
    <ErrorBoundary>
      <MetaDescription
        title={`TechSpecs.Info | Mobile Phone Specifications, Price, Reviews and more`}
        keywords="Techspecs, techspecs.info, mobile phones, mobile specs, phone specifications, mobile price, smartphones, online mobiles, Phone comparison, device specs, Device Specifications, Mobile phone price, Mobile phone images, Mobile phone Finder, Smartphone Features, find your mobile"
        description="Discover, compare, and decide with detailed mobile tech specs and easy comparisons on our site. The ultimate source for news, reviews on the latest phones."
        url=""
        ogUrl="https://www.techspecs.info/"
        ogType="website"
        isNotForHomePage={true}
        robots="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        imageURL=""
        canonical={getCurrentURL()}
        country={`${router.locale}`}
      />
      <Grid className="home-page">
        <Box className="home-brand" py={{ xs: 2, sm: 4.5 }}>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={6}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2000}
            allowTouchMove={false}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              420: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              600: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
            }}
          >
            {brandDevice?.length &&
              brandDevice?.map(
                (
                  data: {
                    brandName: string;
                    slug: string;
                    image: {
                      alternativeText: string | null;
                      url: string | null;
                    };
                    seo: SeoInfo;
                  },
                  i: number,
                ) => {
                  return (
                    <SwiperSlide key={i}>
                      <Link
                        href={`/${ROUTE.MOBILE_PHONES}/${convertIntoString(
                          data.brandName,
                        )}-brand`}
                        aria-label={`visit ${data.brandName} brand`}
                        onClick={() => {
                          if (tagArr?.length < 1) {
                            setTagArr((prev) => [
                              ...prev,
                              {
                                label: 'brand_name',
                                value: convertIntoString(data.brandName),
                                match: data.brandName,
                              },
                            ]);
                          }
                        }}
                        className="d-block"
                      >
                        {data?.image?.url !== undefined ? (
                          <Image
                            className="m-auto"
                            src={`${process.env.API_BASE_URL}${data?.image?.url}`}
                            alt={`${
                              data?.image?.alternativeText !== null
                                ? data?.image?.alternativeText
                                : `${data.brandName} logo`
                            }`}
                            width={160}
                            height={120}
                            style={{
                              objectFit: 'contain',
                              width: '100%',
                              height: '100%',
                              display: isLoaded ? '' : 'none',
                            }}
                          />
                        ) : (
                          <Image
                            className="m-auto"
                            src={defaultSmartPhone}
                            alt={'product images'}
                            width={0}
                            height={0}
                            sizes="100vw"
                            style={{
                              objectFit: 'contain',
                              width: '100%',
                              height: '100%',
                              display: isLoaded ? '' : 'none',
                            }}
                          />
                        )}
                        <Typography
                          className="text-black text-center"
                          mt={0}
                          sx={{
                            fontSize: {
                              xs: '14px',
                              sm: '16px',
                              md: '18px,',
                            },
                            display: isLoaded ? '' : 'none',
                          }}
                        >
                          {data.brandName}
                        </Typography>
                      </Link>
                    </SwiperSlide>
                  );
                },
              )}
          </Swiper>
        </Box>
        <HomeSlider homePageBanner={homePageBanner} />
        <Box
          className="home-product-section section-bottom-gapping"
          mt={{ xs: 1, sm: 2.5 }}
        >
          <ProductBlock
            productDataArray={popular?.filter(
              (res: { slug: string }, i: number) => i <= 7,
            )}
            sectionTitle="Popular Mobiles"
            columnCount={true}
            viewMoreLink={true}
            pagination={false}
            category="popular"
            path={`${ROUTE.POPULAR_PHONES}`}
            paginationData={undefined}
          />
        </Box>
        <Box className="home-product-section">
          <ProductBlock
            productDataArray={featured?.filter(
              (res: { slug: string }, i: number) => i <= 7,
            )}
            sectionTitle="Upcoming Mobiles"
            columnCount={true}
            viewMoreLink={true}
            pagination={false}
            category="Featured"
            path={`${ROUTE.UPCOMING_PHONES}`}
            paginationData={undefined}
          />
        </Box>
      </Grid>
      <LatestBlogs blogs={blogs} />
    </ErrorBoundary>
  );
}
export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const countryCode = context.req.cookies['CountryCode'] || 'US';
  try {
    const popular = await getDeviceByTag('popular');
    const featured = await getDeviceByTag('featured');
    const homeBrandImage = await getBrandAPI(countryCode);
    const selectedBrandData: {
      brandName: string;
      slug: string;
      image: {
        alternativeText: string | null;
        caption: string | null;
        createdAt: string | null;
        ext: string | null;
        folderPath: string | null;
        hash: string | null;
        height: number | null;
        id: number | null;
        mime: string | null;
        name: string | null;
        previewUrl: string | null;
        provider: string | null;
        provider_metadata: string | null;
        size: number | null;
        updatedAt: string | null;
        url: string | null;
        width: number | null;
      };
      seo: {
        canonicalURL: null | string;
        id: number;
        keywords: string;
        metaDescription: string;
        metaImage: null | string;
        metaRobots: string | null;
        metaTitle: string | null;
        metaViewport: string | null;
        structuredData: string | null;
      };
    }[] = [];
    const brandNameSet = new Set();
    if (homeBrandImage?.data?.homePageBrandData?.length) {
      for (const product of homeBrandImage?.data?.homePageBrandData) {
        const brandName = product.brand_name;
        if (!brandNameSet.has(brandName)) {
          brandNameSet.add(brandName);
          selectedBrandData.push({
            brandName,
            slug: product?.slug,
            image: product?.image,
            seo: product?.seo,
          });
        }
      }
    }
    const homePageBanner = await getCachedHomePageBanner();
    const obj = {
      popular,
      featured,
      brandDevice: homeBrandImage.status === 200 ? selectedBrandData : null,
      title:
        'TechSpecs.Info | Mobile Phone Specifications, Price, Reviews and more',
      description:
        'Discover, compare, and decide with detailed mobile Tech specs and easy comparisons on our site. The ultimate source for news, reviews on the latest phones',
      locale: context?.locale,
      countryCode: countryCode,
      homePageBanner: homePageBanner,
      blogs: await getBlogs(),
    };
    return {
      props: obj,
    };
  } catch (error) {
    return error;
  }
};
